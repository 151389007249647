import React, { useState } from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { MetaDataHead, ScrollToTop } from "../components";
import { useRedirectByLang } from "../hooks";
import { getLocaleFromPath } from "../utils/utils";
import { containerStyle, BREAK_POINTS } from "../utils/style";
import { LANGS_MAP_FOR_ASSETS } from "../utils/languages";
import searchIcon from "../assets/images/btn-search.png";
import arrowIcon from "../assets/images/btn-arrow-02.png";
import config from "../config";

const STYLES = {
  clickable: `
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }`,
  activeTag: {
    backgroundColor: "#ffffff",
    color: "#ef529b",
  },
};

const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url("${process.env.GATSBY_ROOT_PATH}/bg-03.jpg") center/cover no-repeat;
  overflow: hidden;
  position: relative;
`;

const PageWrapper = styled.div`
  ${containerStyle}
  height: 100%;
  padding: 0 0 70px 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    padding: 15px 0 30px;
  }
`;

const ContentWrapper = styled.section`
  width: 90%;
  max-width: 1000px;
  background-color: rgba(51, 1, 25, 0.88);
  padding: 30px 20px;
  margin: 0 auto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    padding: 15px 20px;
  }
`;

const TopLogo = styled.div`
  text-align: center;
  cursor: pointer;
  height: 250px;

  img {
    width: 300px;
  }

  &:hover {
    img {
      width: 305px;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    height: 160px;

    img {
      width: 200px;
    }

    &:hover {
      img {
        width: 205px;
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.sm}) {
    margin-top: -10px;
    height: 110px;

    img {
      width: 140px;
    }

    &:hover {
      img {
        width: 143px;
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h1 {
    font-size: 40px;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 22px;
      line-height: 30px;
    }
  }
`;

const SearchInputDiv = styled.div`
  width: 220px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px 2px 20px;

  & > input {
    width: calc(100% - 38px);
    height: 100%;
    border: 0;
    color: #ef529b;
    font-size: 14px;
    font-weight: 700;

    &::placeholder {
      color: #ef529b;
      font-size: 14px;
    }
  }

  & > img {
    height: 100%;
    ${STYLES.clickable}
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 200px;
    height: 34px;
    margin: 12px auto 10px;
  }
`;

const TagBar = styled.div`
  margin-bottom: 40px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    margin-bottom: 20px;
  }
`;

const TagSpan = styled.span`
  border-radius: 4px;
  background-color: #ef529b;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  display: inline-block;
  padding: 2px 10px;
  margin: 0 16px 10px 0;
  ${STYLES.clickable}

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    border-radius: 3px;
    font-size: 13px;
    line-height: 20px;
    padding: 0 8px;
    margin: 0 8px 6px 0;
  }
`;

const QuestionSection = styled.section`
  border-bottom: 2px solid #ef529b;
`;

const QuestionSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${STYLES.clickable}
  padding: 30px 2px;

  h3 {
    font-size: 18px;
    width: calc(100% - 40px);
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    padding: 18px 2px;

    h3 {
      font-size: 16px;
      line-height: 24px;
    }

    img {
      width: 26px;
    }
  }
`;

const AnswerSection = styled.div`
  font-size: 16px;
  word-break: break-word;
  padding-bottom: 30px;

  a {
    color: #ef529b;
  }

  ul {
    padding-left: 22px;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    font-size: 15px;
    padding-bottom: 18px;
  }
`;

const FAQQuestion = ({ locale, faq }) => {
  const [expand, setExpand] = useState(false);

  return (
    <QuestionSection>
      <QuestionSectionHeader onClick={() => setExpand(prevState => !prevState)}>
        <h3>{faq?.frontmatter?.title}</h3>
        <img
          style={{ transform: `rotate(${expand ? "0" : "180"}deg)` }}
          src={arrowIcon}
          alt="arrow-icon"
        />
      </QuestionSectionHeader>
      {expand && (
        <AnswerSection
          dangerouslySetInnerHTML={{
            __html: faq?.html.replace(
              '<a href="mailto:service@sg-arts.com">service@sg-arts.com</a>',
              `<a href=${config.mailto[locale]}>service@sg-arts.com</a>`
            ),
          }}></AnswerSection>
      )}
    </QuestionSection>
  );
};

const TAG_ALL = "all";

const FAQPage = () => {
  useRedirectByLang();

  // query markdown by type in frontmatter
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "faq" } } }) {
        nodes {
          html
          fileAbsolutePath
          frontmatter {
            title
            tag
            tag_order
            order
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale, formatMessage } = intl;
  const lang = LANGS_MAP_FOR_ASSETS[locale];

  // filter faq
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const faqs = nodes.filter(node => getLocaleFromPath(node.fileAbsolutePath) === locale);
  const sorted_faqs = faqs.sort((a, b) => b.frontmatter.order - a.frontmatter.order);

  const [searchInput, setSearchInput] = useState("");
  const [selectedTag, setSelectedTag] = useState(TAG_ALL);
  const [displayedFaqs, setDisplayedFaqs] = useState([...sorted_faqs]);

  // generate sorted tags
  let sorted_tags = [];
  let existed_tags = {};
  faqs.forEach(faq => {
    if (!existed_tags[faq.frontmatter.tag]) {
      sorted_tags.push({
        tag: faq.frontmatter.tag,
        order: faq.frontmatter.tag_order,
      });

      existed_tags[faq.frontmatter.tag] = true;
    }
  });
  sorted_tags.sort((a, b) => b.order - a.order);

  const handleToHome = () => {
    window.location.href = `${process.env.GATSBY_ROOT_PATH}/${locale}`;
  };

  const filterFaqs = tag => {
    if (tag === TAG_ALL) {
      setDisplayedFaqs([...sorted_faqs]);
    } else {
      setDisplayedFaqs([...sorted_faqs.filter(faq => faq.frontmatter.tag === tag)]);
    }
    setSelectedTag(tag);
  };

  const handleSearch = () => {
    const value = searchInput.toLowerCase();
    setDisplayedFaqs([
      ...sorted_faqs.filter(
        faq =>
          faq.frontmatter.title.toLowerCase().includes(value) ||
          faq.html.toLowerCase().includes(value)
      ),
    ]);
  };

  const handleKeyPressSearch = e => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <MetaDataHead>
        <link rel="preload" href={`${process.env.GATSBY_ROOT_PATH}/bg-03.jpg`} as="image" />
      </MetaDataHead>
      <BgWrapper>
        <PageWrapper id="content-container">
          <TopLogo id="back-to-home" onClick={handleToHome}>
            <img src={require(`../assets/images/sub-logo-${lang}.png`).default} alt="logo" />
          </TopLogo>
          <ContentWrapper>
            <Header>
              <h1>{formatMessage({ id: "faq-title" })}</h1>
              <SearchInputDiv>
                <input
                  onChange={e => {
                    setSearchInput(e.target.value);
                  }}
                  value={searchInput}
                  placeholder="search..."
                  onKeyPress={handleKeyPressSearch}
                />
                <img src={searchIcon} alt="search-icon" onClick={handleSearch} />
              </SearchInputDiv>
            </Header>

            <TagBar>
              <TagSpan
                style={selectedTag === TAG_ALL ? { ...STYLES.activeTag } : {}}
                onClick={() => {
                  filterFaqs(TAG_ALL);
                }}>
                All
              </TagSpan>
              {sorted_tags.map((tagObj, index) => (
                <TagSpan
                  key={`tag_${index}`}
                  style={selectedTag === tagObj.tag ? { ...STYLES.activeTag } : {}}
                  onClick={() => {
                    filterFaqs(tagObj.tag);
                  }}>
                  {tagObj.tag}
                </TagSpan>
              ))}
            </TagBar>

            {displayedFaqs.map((faq, index) => {
              return <FAQQuestion key={`faq_${index}`} locale={locale} faq={faq} />;
            })}

            <ScrollToTop />
          </ContentWrapper>
        </PageWrapper>
      </BgWrapper>
    </>
  );
};

export default FAQPage;
